#interest {
    position:relative;
    height:225px;
    width:225px;
    margin:0 auto;
  }
  
  #interest img {
    height:225px;
    width:225px;
    border-radius: 50%;
    position:absolute;
    left:0;
    -webkit-transition: opacity .5s ease-in-out;
    -moz-transition: opacity .5s ease-in-out;
    -o-transition: opacity .5s ease-in-out;
    transition: opacity .5s ease-in-out;
  }
  
#interest img.top:hover {
    opacity:0;
  }

  #interestMobile {
    position:relative;
    height:150px;
    width:150px;
    margin:0 auto;
    cursor: pointer;
  }
  
  #interestMobile img {
    height:150px;
    width:150px;
    border-radius: 50%;
    position:absolute;
    left:0;
    -webkit-transition: opacity .5s ease-in-out;
    -moz-transition: opacity .5s ease-in-out;
    -o-transition: opacity .5s ease-in-out;
    transition: opacity .5s ease-in-out;
  }
  
#interestMobile img.top:hover {
    opacity:0;
  }