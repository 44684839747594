#project {
    position:relative;
    height:95px;
    width:100px;
    margin:0 auto;
  }
  
#project img {
    height:95px;
    width:95px;
    position:absolute;
    border-radius: 20%;
    left:0;
    /* If you change this duration, please update the duration in fadingTextPropsTransition in StockProfiles.js */
    -webkit-transition: opacity .5s ease-in-out;
    -moz-transition: opacity .5s ease-in-out;
    -o-transition: opacity .5s ease-in-out;
    transition: opacity .5s ease-in-out;
  }
  
#project img.top:hover {
    opacity:0;
  }

#project0 {
    position:relative;
    height:95px;
    width:95px;
    margin:0 auto;
    margin-top: 90px;
    margin-left: -19px;
}
  
#project0 img {
    height:95px;
    width:95px;
    position:absolute;
    border-radius: 20%;
    left:0;
    cursor:pointer;
    /* If you change this duration, please update the duration in fadingTextPropsTransition in StockProfiles.js */
    -webkit-transition: opacity .5s ease-in-out;
    -moz-transition: opacity .5s ease-in-out;
    -o-transition: opacity .5s ease-in-out;
    transition: opacity .5s ease-in-out;
  }
  
#project0 img.top:hover {
    opacity:0;
  }

#project1 {
    position:relative;
    height:95px;
    width:95px;
    margin:0 auto;
    margin-top: 90px;
}
  
#project1 img {
    height:95px;
    width:95px;
    position:absolute;
    border-radius: 20%;
    left:0;
    cursor:pointer;
    /* If you change this duration, please update the duration in fadingTextPropsTransition in StockProfiles.js */
    -webkit-transition: opacity .5s ease-in-out;
    -moz-transition: opacity .5s ease-in-out;
    -o-transition: opacity .5s ease-in-out;
    transition: opacity .5s ease-in-out;
  }
  
#project1 img.top:hover {
    opacity:0;
  }
  
  #project2 {
    position:relative;
    height:95px;
    width:95px;
    margin:0 auto;
    margin-left: -19px;
}
  
#project2 img {
    height:95px;
    width:95px;
    position:absolute;
    border-radius: 20%;
    left:0;
    cursor:pointer;
    /* If you change this duration, please update the duration in fadingTextPropsTransition in StockProfiles.js */
    -webkit-transition: opacity .5s ease-in-out;
    -moz-transition: opacity .5s ease-in-out;
    -o-transition: opacity .5s ease-in-out;
    transition: opacity .5s ease-in-out;
  }
  
#project2 img.top:hover {
    opacity:0;
  }

  #project3 {
    position:relative;
    height:95px;
    width:95px;
    margin:0 auto;
}
  
#project3 img {
    height:95px;
    width:95px;
    position:absolute;
    border-radius: 20%;
    left:0;
    cursor:pointer;
    /* If you change this duration, please update the duration in fadingTextPropsTransition in StockProfiles.js */
    -webkit-transition: opacity .5s ease-in-out;
    -moz-transition: opacity .5s ease-in-out;
    -o-transition: opacity .5s ease-in-out;
    transition: opacity .5s ease-in-out;
  }
  
#project3 img.top:hover {
    opacity:0;
  }

  #project4 {
    position:relative;
    height:95px;
    width:95px;
    margin:0 auto;
    margin-left: -19px;
}
  
#project4 img {
    height:95px;
    width:95px;
    position:absolute;
    border-radius: 20%;
    left:0;
    cursor:pointer;
    /* If you change this duration, please update the duration in fadingTextPropsTransition in StockProfiles.js */
    -webkit-transition: opacity .5s ease-in-out;
    -moz-transition: opacity .5s ease-in-out;
    -o-transition: opacity .5s ease-in-out;
    transition: opacity .5s ease-in-out;
  }
  
#project4 img.top:hover {
    opacity:0;
  }

  #project5 {
    position:relative;
    height:95px;
    width:95px;
    margin:0 auto;

}
  
#project5 img {
    height:95px;
    width:95px;
    position:absolute;
    border-radius: 20%;
    left:0;
    cursor:pointer;
    /* If you change this duration, please update the duration in fadingTextPropsTransition in StockProfiles.js */
    -webkit-transition: opacity .5s ease-in-out;
    -moz-transition: opacity .5s ease-in-out;
    -o-transition: opacity .5s ease-in-out;
    transition: opacity .5s ease-in-out;
  }
  
#project5 img.top:hover {
    opacity:0;
  }

#projectsGrid {
  position: relative;
  background-image: url("media/iphone.png");
  background-repeat: no-repeat;
  background-size: 575px 575px;
  background-position: center;
  height: 600px;
}